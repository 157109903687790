import {POSITION_TYPES} from 'hooks';
import useSizePercentage from 'hooks/Product/DoorDesign';
import React, {useState} from 'react';
import {Form, InputGroup} from 'react-bootstrap';

export const SizePercentage = ({
    name,
    fieldsetName,
    selectHandler,
    value,
    metric,
    disabled = false,
    ...otherParams
}) => {
    const [isInvalid, setIsInvalid] = useState('');
    const {
        displayValue,
        decimalValue,
        setDisplayValue,
        setShowPercentage,
        showPercentage,
        setDecimalValue,
        heightReference,
        shelvesPositionType,
    } = useSizePercentage(value, fieldsetName);

    const percentageHandlerFocus = () => {
        if (showPercentage) {
            setDisplayValue(+decimalValue.toFixed(4));
        }
    };

    const percentageHandlerBlur = () => {
        let localValue = parseFloat(displayValue);
        setIsInvalid('');

        if (localValue < 0) {
            localValue = 0;
            setDecimalValue(0);
        } else {
            if (localValue < 1) {
                if (localValue == value) {
                    setDisplayValue(`${+(decimalValue * 100).toFixed(4)}%`);
                } else {
                    selectHandler(name, localValue);
                    setShowPercentage(true);
                }
            } else {
                if (localValue > heightReference) {
                    selectHandler(name, localValue);
                    setIsInvalid(
                        `Value cannot be greater than ${heightReference}`
                    );
                } else {
                    if (localValue != value) {
                        selectHandler(name, localValue);
                        setShowPercentage(false);
                    }
                }
            }
        }
    };

    return (
        <InputGroup className="cbc-size">
            <Form.Control
                disabled={disabled}
                onFocus={percentageHandlerFocus}
                onBlur={percentageHandlerBlur}
                type="text"
                name={name}
                value={displayValue}
                onChange={(event) => {
                    setDisplayValue(event.target.value);
                }}
                isInvalid={isInvalid != '' || otherParams?.isInvalid}
            />
            {shelvesPositionType[fieldsetName] == POSITION_TYPES.DECIMAL &&
            displayValue >= 1 ? (
                <InputGroup.Append>
                    <InputGroup.Text>{metric}</InputGroup.Text>
                </InputGroup.Append>
            ) : (
                <></>
            )}

            {isInvalid != '' && (
                <Form.Control.Feedback type="invalid">
                    {isInvalid}
                </Form.Control.Feedback>
            )}
        </InputGroup>
    );
};
